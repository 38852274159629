<template>
  <div>
    <header>
        <div class="container">
            <nav class="navbar-dark bg-transparenet">
                <a class="navbar-brand" href="#">
                    ㈜미르네트웍스
                </a>
                <span class="navbar-text ml-auto d-none d-sm-inline-block"></span>
                <span class="navbar-text d-none d-sm-inline-block"></span>
            </nav>
        </div>
    </header>
    <main class="my-auto">
        <div class="container" style="padding-top: 150px">
            <h1 class="page-title">{{ msg }}</h1>
            <p class="page-description">스마트팜, 생산자 직거래 플랫폼, 신선식품 배달앱, 키오스크 개발</p>
        </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
  props: {
    msg: String
  }
}
</script>

