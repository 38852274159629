<template>
  <div id="app">
    <ComingSoon msg="We're coming soon"/>
  </div>
</template>

<script>
import ComingSoon from './components/ComingSoon.vue'

export default {
  name: 'App',
  components: {
    ComingSoon
  },
  created: () => {
    document.title = '㈜미르네트웍스';
  },
  mounted() {
    this.test();
  },
  methods: {
    test() {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
      }, (err) => {
        console.log(`>> ${err.message}`);
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #fff;
  margin-top: 0px;
  width: 100%;
  height: 100%;
}
</style>
